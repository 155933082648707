
import { mapActions } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import { AxiosResponse, AxiosError } from 'axios';
import LoginService from './services/login.service';

@Component({
  methods: {
    ...mapActions(['setToken', 'setIsAuthenticated']),
  },
})
export default class Login extends Vue {
  private setIsAuthenticated!: Function;

  private created() {
    this.setIsAuthenticated(false);
    window.localStorage.clear();
  }
}
